<template>
  <main class="pb-6 bg-white">
    <div class="relative pb-32 bg-gray-800">
      <div class="absolute inset-0">
        <img class="hidden object-cover object-bottom w-full h-full md:block" src="../assets/pand.webp" width="1280" height="547" alt="bedrijfspand_groot">
        <img class="block object-cover object-bottom w-full h-full md:hidden" src="../assets/pand_small.webp" width="320" height="137" alt="bedrijfspand">
        <div class="absolute inset-0 bg-gray-800" style="mix-blend-mode: hard-light;" aria-hidden="true"></div>
      </div>
      <div class="relative py-24 px-4 mx-auto max-w-7xl sm:py-32 sm:px-6 lg:px-8">
        <h1 class="text-3xl font-medium tracking-tight text-white md:text-5xl lg:text-6xl">JC Las & Montagetechniek</h1>
        <p class="mt-6 max-w-3xl text-base text-gray-300">
          JC Las en Montagetechniek is, naast staal en aluminium, gespecialiseerd in RVS werkzaamheden.
          Wij zijn perfectionisten als het gaat om
          <router-link class="font-semibold text-red-900" to="/lassen"> laswerkzaamheden</router-link>
          , leidingwerk en constructiebouw.
          Met ons specialisme in RVS en onder andere
          <router-link class="font-semibold text-red-900" to="/lassen"> TIG lassen</router-link>
          .
          bent u verzekerd van hoogstaand en duurzaam resultaat.
        </p>
      </div>
    </div>

    <section class="relative z-10 px-4 pb-32 mx-auto -mt-32 max-w-7xl sm:px-6 lg:px-8">
      <h2 class="sr-only">Montage en onderhoud</h2>
      <div class="grid grid-cols-1 gap-y-20 lg:grid-cols-3 lg:gap-y-0 lg:gap-x-8">
        <div class="bg-white shadow-sm shadow rounded-xs">
          <div class="relative px-6 pt-16 pb-8 md:px-8">
            <h2 class="text-xl font-medium text-red-900">Montage en onderhoud</h2>
            <p class="mt-4 text-base text-gray-600 line-clamp-3">
              Bij JC Las en Montagetechniek zijn we gespecialiseerd in het
              <router-link class="font-semibold text-red-900" to="/montage-en-onderhoud">monteren en onderhouden.</router-link>
              van hoogwaardige metaalproducten.
            </p>
          </div>
          <div class="p-6 bg-gray-50 md:px-8">
            <router-link to="/montage-en-onderhoud" class="text-base font-medium text-red-900">Lees meer<span aria-hidden="true"> &rarr;</span>
            </router-link>
          </div>
        </div>

        <div class="bg-white shadow-sm shadow rounded-xs">
          <div class="relative px-6 pt-16 pb-8 md:px-8">
            <h2 class="text-xl font-medium text-red-900">Gecertificeerd RVS Lassen</h2>
            <p class="mt-4 text-base text-gray-600 line-clamp-3">
              Bij JC las en Montagetechniek zijn we gespecialiseerd en gecertificeerd in het
              <router-link class="font-semibold text-red-900" to="/lassen">lassen van roestvrij staal</router-link>
              .
            </p>
          </div>
          <div class="p-6 bg-gray-50 md:px-8">
            <router-link to="/lassen" class="text-base font-medium text-red-900">Lees meer<span aria-hidden="true"> &rarr;</span>
            </router-link>
          </div>
        </div>

        <div class="bg-white shadow-sm shadow rounded-xs">
          <div class="relative px-6 pt-16 pb-8 md:px-8">
            <h2 class="text-xl font-medium text-red-900">Machines en onderdelen</h2>
            <p class="mt-4 text-base text-gray-600 line-clamp-3">
              JC las en Montagetechniek is gespecialiseerd in het monteren en vervaardigen van
              <router-link class="font-semibold text-red-900" to="machines-en-onderdelen"> machines en onderdelen.</router-link>
            </p>
          </div>
          <div class="p-6 bg-gray-50 md:px-8">
            <router-link to="machines-en-onderdelen" href="#" class="text-base font-medium text-red-900">Lees meer<span aria-hidden="true"> &rarr;</span>
            </router-link>
          </div>
        </div>
      </div>
    </section>

    <div class="overflow-hidden mb-12 lg:mb-4">
      <div class="px-4 mx-auto space-y-8 max-w-7xl sm:px-6 lg:px-8">
        <div class="mx-auto text-base lg:max-w-none">
          <h1 class="mt-2 text-3xl font-extrabold tracking-tight leading-8 text-gray-900 sm:text-4x">Las en montagetechniek Overijssel</h1>
        </div>
        <div class="relative z-10 mx-auto text-base lg:max-w-5xl lg:mx-0 lg:pr-72">
          <p class="text-base text-gray-600">
            Naast ons specialisme in de installatie van RVS leiding systemen in de voedingsmiddelenindustrie en
            <router-link class="font-semibold text-red-900" to="/machines-en-onderdelen">machinebouw</router-link>
            ,
            vervaardigen we ook licht constructiewerk in de bouw. Denk hierbij aan frames, bordessen, trappen, leuningen in staal en RVS.
            Ook eigen ontwerpen rekenen we graag voor u na, om het vervolgens vakkundig te produceren.
          </p>
        </div>
        <div class="lg:grid lg:grid-cols-2 lg:gap-8 lg:items-start">
          <div class="relative z-10">
            <p class="mx-auto text-gray-600 lg:max-w-none">
              Vrijwel alles wat met
              <router-link class="font-semibold text-red-900" to="/lassen">lassen</router-link>
              of
              <router-link class="font-semibold text-red-900" to="/montage-en-onderhoud"> montage</router-link>
              te maken heeft, hebben wij in huis.
              Neem contact met ons op voor alle mogelijkheden.
              Zowel in nieuwbouw, verbouw als onderhoud. Inventief, snel en flexibel en onovertroffen in prijs kwaliteit verhouding.
              U kunt met en op ons rekenen.
            </p>
            <div class="flex mx-auto mt-10 text-base lg:max-w-none">
              <div class="shadow">
                <router-link to="/contact" class="flex justify-center items-center py-3 px-5 w-full text-base font-medium rounded-sm border border-gray-800 hover:bg-gray-50">
                  Contact opnemen
                </router-link>
              </div>
            </div>
          </div>
          <div class="relative mx-auto mt-12 max-w-prose text-base lg:mt-0 lg:max-w-none">
            <svg class="absolute top-0 right-0 -mt-20 -mr-20 lg:top-auto lg:right-auto lg:bottom-1/2 lg:left-1/2 lg:mt-0 lg:mr-0 xl:top-0 xl:right-0 xl:-mt-20 xl:-mr-20" width="404" height="384" fill="none" viewBox="0 0 404 384" aria-hidden="true">
              <defs>
                <pattern id="bedc54bc-7371-44a2-a2bc-dc68d819ae60" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
                  <rect x="0" y="0" width="4" height="4" class="text-gray-200" fill="currentColor"/>
                </pattern>
              </defs>
              <rect width="404" height="384" fill="url(#bedc54bc-7371-44a2-a2bc-dc68d819ae60)"/>
            </svg>
            <section class="hidden relative bg-white rounded-sm shadow-lg lg:block">
              <div class="py-8 px-6 rounded-t-lg sm:px-10 sm:pt-10 sm:pb-8">
                <img class="w-9/12" src="../assets/metaalunie.svg" alt="Metaalunie" width="300" height="80">
              </div>
            </section>
          </div>
        </div>
      </div>
    </div>

    <Tinybox
        v-model="index"
        :images="images"
        :loop="loopCheckbox"
        :no-thumbs="!thumbsCheckbox"
    ></Tinybox>

    <section class="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8 lg:py-20">
      <ul role="list" class="grid grid-cols-2 md:grid-cols-4">
        <li v-for="(img, idx) in images" :key="idx" @click="index = idx" class="relative">
          <div class="block overflow-hidden w-full group aspect-w-10 aspect-h-7">
            <img :src="img.thumbnail" width="300" height="300" :alt="img.alt" class="object-cover pointer-events-none group-hover:opacity-75">
            <button type="button" class="absolute inset-0">
              <span class="sr-only">{{ img.caption }}</span>
            </button>
          </div>
        </li>
      </ul>
    </section>
  </main>
</template>

<script>
import Tinybox from "vue-tinybox";

export default {
  name : 'Home',

  components : {
    Tinybox
  },

  data() {
    return {
      images         : [
        {
          src       : '/assets/img/9204_img.webp',
          alt       : 'Image alttext',
          thumbnail : '/assets/img/9204_thumb.webp',
          caption   : ''
        },
        {
          src       : '/assets/img/9206_img.webp',
          alt       : 'Image alttext',
          thumbnail : '/assets/img/9206_thumb.webp',
          caption   : ''
        },
        {
          src       : '/assets/img/9207_img.webp',
          alt       : 'Image alttext',
          thumbnail : '/assets/img/9207_thumb.webp',
          caption   : ''
        },
        {
          src       : '/assets/img/9205_img.webp',
          alt       : 'Image alttext',
          thumbnail : '/assets/img/9205_thumb.webp',
          caption   : ''
        }
      ],
      index          : null,
      loopCheckbox   : false,
      thumbsCheckbox : true
    };
  },

  metaInfo : {
    title : 'Homepage',
    meta  : [
      {itemprop : 'name', content : 'JC Las & montagetechniek'},
      {
        itemprop : 'description',
        content  : 'Las en montagetechniek Overijssel '
      },
      {itemprop : 'image', content : '../assets/logo_small.webp'},
      {
        name    : 'googlebot',
        content : 'index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1'
      },
      {
        name    : 'description',
        content : 'Met onze ruime ervaring en gecertificeerde vakmensen helpen wij u graag vooruit.'
      }
    ]
  }
}
</script>

<style>

</style>
